import { w as withSharedProps, _ as __rest, a as __assign } from './withSharedProps-a1728349.js';
import React from 'react';
import { s as styleInject, a as secondaryColorDefaultProps } from './style-inject.es-fc9e633e.js';

var css_248z = "@keyframes spinners-react-circular{0%{stroke-dashoffset:306}50%{stroke-dasharray:40,134}to{stroke-dasharray:1,174;stroke-dashoffset:132}}";
styleInject(css_248z);

var Component = function (_a) {
    var secondaryColor = _a.secondaryColor, speed = _a.speed, still = _a.still, thickness = _a.thickness, svgProps = __rest(_a, ["secondaryColor", "speed", "still", "thickness"]);
    var strokeWidth = 4 * (thickness / 100);
    var circleStyle = !still
        ? { animation: "spinners-react-circular " + 140 / speed + "s linear infinite" }
        : {};
    return (React.createElement("svg", __assign({ fill: "none" }, svgProps, { viewBox: "0 0 66 66" }),
        React.createElement("circle", { cx: "33", cy: "33", fill: "none", r: "28", stroke: secondaryColor, strokeWidth: strokeWidth }),
        React.createElement("circle", { cx: "33", cy: "33", fill: "none", r: "28", stroke: "currentColor", strokeDasharray: "1, 174", strokeDashoffset: "306", strokeLinecap: "round", strokeWidth: strokeWidth, style: circleStyle })));
};
Component.defaultProps = secondaryColorDefaultProps;
var SpinnerCircular = withSharedProps(Component);

export { SpinnerCircular };
